/* purgecss start ignore */
@tailwind base;
@tailwind components;

/* Your own custom component styles */

/* purgecss end ignore */
@tailwind utilities;

@layer components {
  .all-\[unset\] {
    all: unset;
  }
}

html {
  scrollbar-gutter: stable;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  cursor: pointer;
}

.main-nav-container {
  background-color: #616461;

  .mobile-menu {
    .nav-active {
      background: #202e59;
      border-radius: 8px;
    }
  }
  .desktop-menu {
    .nav-active {
      background: #202e59;
      border-radius: 8px;
    }
  }
}

html, body, #root {
 min-height: 100vh;
  margin: 0;
  background-color: #e5e6eb;
}
